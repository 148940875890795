import React from 'react';
import { dripSubscribe } from '../../utils/drip';
import styles from './index.module.scss';

interface FooterNewsletterProps {
  slice: FooterNewsletterSlice;
}

export interface FooterNewsletterSlice {
  id: string;
  name: string;
  docURL: string;
  version: string;
  description: string;
  primary: Primary;
  items: any[];
  slice_type: string;
}

interface Primary {
  firstLevelTitle: string;
  subtitle: string;
  placeholder: string;
}

const onSubmit = (e) => {
  dripSubscribe(e.target.email.value);
  e.preventDefault();
};

const FooterNewsletter = ({ slice }: FooterNewsletterProps) => (
  <section className={styles.footerMenuWrapper}>
    <div className={styles.footerMenuTitle}>
      {slice.primary.firstLevelTitle}
    </div>
    <div className={styles.footerMenuSubtitle}>{slice.primary.subtitle}</div>
    <form onSubmit={onSubmit} className={styles.emailInput}>
      <input
        type="email"
        name="email"
        placeholder={slice.primary.placeholder}
        className={styles.textInput}
      />
      <button className={styles.validationButton} />
    </form>
  </section>
);

export default FooterNewsletter;
