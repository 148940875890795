import React from 'react';
import cn from 'classnames';
import Link from 'next/link';

import { Background } from 'react-imgix';
import SwiperCore, { Pagination } from 'swiper/core';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

import Button from '../../components/button/button';
import styles from './index.module.scss';
import ReviewsCarousel from '../../components/reviews/Carousel';
import urls from '../../utils/urls';

SwiperCore.use([Pagination]);

const TITLE = 'Satisfaction client';
const PERCENTAGE = '97,8%';
const SUBTITLE = 'recommanderaient les soins Laboté*';
const SEE_ALL = 'Voir tous les avis';
const DISCLAIMER = '* Résultats d’une enquête auprès de nos clients, oct. 2019';

const HomeReview = ({ slice }) => {
  return (
    <section className={styles.reviewsContainer}>
      <div className={styles.leftContent}>
        <Background
          src={slice.primary.macaron.url}
          className={styles.backgroundWrapper}
        >
          <div className={styles.backgroundContent}>
            <div className={styles.title}>{TITLE}</div>
            <div className={cn(styles.title, styles.percentage)}>
              {PERCENTAGE}
            </div>
            <div className={cn(styles.title, styles.subtitle)}>{SUBTITLE}</div>
            <div className={styles.ctaStyle}>
              <div className={styles.cta__wrapper}>
                <Link href={urls.REVIEW}>
                  <a className="button--dark">
                    <span className={cn('margin-left-24', 'margin-right-24')}>
                      {SEE_ALL}
                    </span>
                  </a>
                </Link>
              </div>
            </div>
            <div className={styles.footnote}>{DISCLAIMER}</div>
          </div>
        </Background>
      </div>
      <div className={styles.rightContent}>
        <ReviewsCarousel reviewData={slice.primary.reviewData} />
      </div>
    </section>
  );
};

export default HomeReview;
