import React from 'react';
import cn from 'classnames';
import Imgix from 'react-imgix';

import { RichText, RichTextBlock, Link } from 'prismic-reactjs';
import { linkResolver } from '../../prismic-configuration';
import styles from './index.module.scss';
import { screenWidth } from '../../styles/variables';

interface TextPictureSlice {
  primary: {
    pictureOnRight: boolean;
    ctaText: string;
    ctaLink: Link;
    text: RichTextBlock[];
    image: {
      url: string;
      alt: string;
    };
  };
}

interface TextPictureProps {
  slice: TextPictureSlice;
}

const IMGIX_SIZES = `
  (max-width: ${screenWidth.maxScreenLandscape}) 100vw,
  33vw
`;

const IMG_RATIO = '23:24';

const TextPicture = ({ slice }: TextPictureProps) => {
  const { image, pictureOnRight, text, ctaLink, ctaText } = slice.primary;
  const noPicture = !image?.url;

  return (
    <>
      <div className={cn(styles['wrap-before'])} />
      <section
        className={cn(styles.textPicture, {
          [styles.pictureLeft]: !pictureOnRight,
          [styles['text-picture--no-picture']]: noPicture,
        })}
      >
        <div
          className={cn(styles.content, {
            [styles.noPicture]: noPicture,
          })}
        >
          <div className={cn(styles.text, 'abril--line')}>
            <RichText render={text} linkResolver={linkResolver} />
          </div>
          {!!ctaText && !!ctaLink ? (
            <a href={Link.url(ctaLink, linkResolver)} target={ctaLink.target}>
              <button className={cn('button--dark', 'button--light')}>
                <div className="padding-h-24">{ctaText}</div>
              </button>
            </a>
          ) : null}
        </div>
        {!noPicture ? (
          <Imgix
            src={image.url}
            alt={image.alt}
            className={styles.image}
            sizes={IMGIX_SIZES}
            imgixParams={{ ar: IMG_RATIO, crop: 'entropy' }}
          />
        ) : null}
      </section>
      <div className={cn(styles['wrap-after'])} />
    </>
  );
};

export default TextPicture;
