import React, { useEffect, useRef, useState } from 'react';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import cn from 'classnames';

import 'swiper/components/navigation/navigation.min.css';

import ProductDisplay from '../../components/productDisplay/productDisplay';
import styles from './index.module.scss';
import IProduct from '../..//interfaces/product';
import { IAccessory } from '../../interfaces/accessory';
import { IProductPageDetailledProduct } from '../../interfaces/productPage';

interface ProductCarouselProps {
  slice: {
    items: {
      product: IProduct;
      productPage: IProductPageDetailledProduct;
      accessory: IAccessory;
    }[];
    productDisplayText?: (product: IProduct) => string;
    productDisplayCtaFunc?: (product: IProduct) => void;
  };
  withShop?: boolean;
}

SwiperCore.use([Pagination, Navigation]);

// CAUTION : This slice can’t be updated with slice machine because it use integration field
const ProductCarousel = ({ slice, withShop = false }: ProductCarouselProps) => {
  const numberOfSlides = slice.items.length;

  const nextEltRef = useRef(null);
  const prevEltRef = useRef(null);

  const [forceRerender, setForceRerender] = useState(false);
  useEffect(() => {
    setForceRerender(true);
  }, []);

  return (
    <section
      className={cn(styles.productCarousel, {
        [styles['productCarousel-3']]: numberOfSlides <= 3,
        [styles['productCarousel-2']]: numberOfSlides <= 2,
        [styles['productCarousel-1']]: numberOfSlides <= 1,
      })}
      role="products-presentation"
    >
      <div
        ref={prevEltRef}
        className={cn('swiper-button-prev', styles['swiper-left'])}
      />
      <div
        ref={nextEltRef}
        className={cn('swiper-button-next', styles['swiper-right'])}
      />
      <Swiper
        pagination={{
          dynamicBullets: true,
          clickable: true,
        }}
        navigation={{
          nextEl: nextEltRef.current,
          prevEl: prevEltRef.current,
        }}
        slidesPerView={1.1}
        grabCursor
        breakpoints={{
          768: {
            slidesPerView: 2,
            centeredSlides: false,
            centeredSlidesBounds: false,
          },
          992: {
            slidesPerView: Math.max(3, Math.min(numberOfSlides, 3.1)),
            centeredSlides: false,
            centeredSlidesBounds: false,
          },
        }}
        watchOverflow={true}
        centeredSlidesBounds={true}
      >
        {slice.items.map((item, idx) => {
          return (
            <SwiperSlide
              key={`${
                (item?.product ?? item?.productPage ?? item?.accessory).id
              } ${idx}`}
            >
              <ProductDisplay
                product={item.product}
                productPage={item.productPage}
                accessory={item.accessory}
                withShop
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default ProductCarousel;
