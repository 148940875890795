import cn from 'classnames';
import React, { useMemo, useState, Fragment } from 'react';
import Measure from 'react-measure';

import Imgix from 'react-imgix';
import { RichText, RichTextBlock, Link } from 'prismic-reactjs';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper/core';

import { linkResolver } from '../../prismic-configuration';
import Button from '../../components/button/button';
import styles from './index.module.scss';
import { screenWidth } from '../../styles/variables';

interface MediaTextProps {
  slice: MediaTextSlice;
}

interface MediaTextSlice {
  id: string;
  name: string;
  docURL: string;
  version: string;
  description: string;
  primary: Primary;
  items: Item[];
  slice_type: string;
}

interface Item {
  image: Image;
  listEntry: string;
  subtitle1: RichTextBlock[];
  subtitle2: RichTextBlock[];
  contentText: RichTextBlock[];
}

interface Image {
  dimensions: Dimensions;
  alt: string;
  copyright: null;
  url: string;
}

interface Dimensions {
  width: number;
  height: number;
}

interface Primary {
  media: Media;
  contentTitle: RichTextBlock[];
  contentText: RichTextBlock[];
  ctaText: string;
  ctaLink: Link;
  textPosition: string;
}

interface Media {
  url: string;
  author_name: string;
  author_url: string;
  html: string;
  width: number;
  height: null;
  type: string;
  cache_age: string;
  provider_name: string;
  provider_url: string;
  version: string;
  embed_url: string;
}

interface ComponentMediaProps {
  images: Image[];
  media: Media;
}

SwiperCore.use([Autoplay]);

const formatBulletPoint = (i: number) => String(i).padStart(2, '0');

const IMGIX_SIZES = `
  (max-width: ${screenWidth.maxScreenSmall}) 100vw,
  42vw
`;

const ComponentMedia = ({ images, media }: ComponentMediaProps) => {
  const [dimensions, setDimensions] = useState({ width: -1, height: -1 });

  if (images.length === 0) {
    const html = media.html
      .replace('width="200"', '')
      .replace('height="113"', '');
    return (
      <Measure
        bounds
        onResize={(contentRect) => {
          const { width: newWidth } = contentRect.bounds;
          setDimensions({ width: newWidth, height: (newWidth * 9) / 16 });
        }}
      >
        {({ measureRef }) => (
          <div
            style={{
              height: `${dimensions.height}px`,
            }}
            ref={measureRef}
            dangerouslySetInnerHTML={{ __html: html }}
            className={styles.videoMedia}
          />
        )}
      </Measure>
    );
  } else {
    if (images.length > 0) {
      return (
        <Swiper
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
          grabCursor
        >
          {images.map((image, idx) => (
            <SwiperSlide key={idx}>
              <Imgix
                sizes={IMGIX_SIZES}
                src={image.url}
                alt={image.alt}
                className={styles.imageMedia}
                imgixParams={{ crop: 'entropy' }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      );
    } else {
      const image = images[0];
      <Imgix
        sizes="42vw"
        src={image.url}
        alt={image.alt}
        className={styles.imageMedia}
        imgixParams={{ crop: 'entropy' }}
      />;
    }
  }
};

interface ComponentTextProps {
  contentText: RichTextBlock[];
  bulletPointList: string[];
  contentList: {
    subtitle1: RichTextBlock[];
    subtitle2: RichTextBlock[];
    contentText: RichTextBlock[];
  }[];
}

const ComponentText = ({
  bulletPointList,
  contentText,
  contentList,
}: ComponentTextProps) => {
  if (bulletPointList.length === 0) {
    return (
      <>
        {contentList.map((content, idx) => (
          <Fragment key={idx}>
            <div className={cn(styles['subtitle1'], 'abril--line')}>
              <RichText
                render={content.subtitle1}
                linkResolver={linkResolver}
              />
            </div>
            <div className={cn(styles['subtitle2'], 'roboto--line')}>
              <RichText
                render={content.subtitle2}
                linkResolver={linkResolver}
              />
            </div>
            <div className={cn('abril--line', 'text')}>
              <RichText
                render={content.contentText}
                linkResolver={linkResolver}
              />
            </div>
          </Fragment>
        ))}
      </>
    );
  } else {
    return (
      <ul className={styles.bulletPointList}>
        {bulletPointList.map((entry, idx) => (
          <li key={idx} className={styles.bulletPoint}>
            <span>{formatBulletPoint(idx + 1)}</span>
            <span>{entry}</span>
          </li>
        ))}
      </ul>
    );
  }
};

const MediaText = ({ slice }: MediaTextProps) => {
  const images: Image[] = useMemo(
    () => slice.items.map((i) => i.image).filter((i) => i?.url),
    [slice.items],
  );
  const bulletPointList: string[] = useMemo(
    () => slice.items.map((i) => i.listEntry).filter(Boolean),
    [slice.items],
  );

  return (
    <section className={styles.mediaTextContainer}>
      <div
        className={cn(
          styles.contentWrapper,
          styles[slice.primary.textPosition],
        )}
      >
        <div className={styles.mediaContainer}>
          <ComponentMedia images={images} media={slice.primary.media} />
        </div>
        <div
          className={cn(styles.textContainer, {
            [styles['bullet-point']]: bulletPointList.length > 0,
          })}
        >
          <div className={cn(styles.contentTitle, 'title--line')}>
            <RichText
              render={slice.primary.contentTitle}
              linkResolver={linkResolver}
            />
          </div>
          <ComponentText
            bulletPointList={bulletPointList}
            contentText={slice.primary.contentText}
            contentList={slice.items}
          />
          <a
            className="margin-top-30"
            href={Link.url(slice.primary.ctaLink, linkResolver)}
          >
            <Button content={slice.primary.ctaText} />
          </a>
        </div>
      </div>
    </section>
  );
};

export default MediaText;
