import React from 'react';
import styles from './index.module.scss';

const MySlice = ({ slice }) => {
  const media = slice.primary.media;
  const html = media.html
    .replace('width="200"', '')
    .replace('height="113"', '');
  return (
    <div
      dangerouslySetInnerHTML={{ __html: html }}
      className={styles['video-media']}
    />
  );
};

export default MySlice;
