import c from '../../styles/colours';

export const productSelectStyle = (stateValue) => ({
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? c.orange : null,
    '&:active': { backgroundColor: c.orange_opacity_30 },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: `${c.black}`,
  }),
  placeholder: (base) => ({
    ...base,
    color: `${c.orange}`,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: `${c.orange}`,
  }),
  input: (base) => ({
    color: `${c.khaki}`,
  }),

  control: (base, state) => ({
    ...base,
    background: `${c.platinium}`,
    boxShadown: 'none',
    border: `1px solid ${state.isSelected ? c.orange : c.khaki}`,
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Roboto Mono',
    color: `${c.orange}`,
    textTransform: 'uppercase',
    '&:hover': { borderColor: c.orange },
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
    color: 'c.khaki',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Roboto Mono',
    textTransform: 'uppercase',
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
});

export const countrySelectStyle = (stateValue) => ({
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? c.orange : null,
    '&:active ': { backgroundColor: c.orange_opacity_30 },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: `${c.black}`,
    marginLeft: '0',
  }),
  valueContainer: (base) => ({
    ...base,
    paddingLeft: '15px',
  }),
  placeholder: (base) => ({
    ...base,
    marginLeft: '0',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: `${c.khaki}`,
  }),
  input: (base) => ({
    color: `${c.khaki}`,
  }),

  control: (base, state) => ({
    ...base,
    height: `50px`,
    background: `${c.platinium}`,
    boxShadown: 'none',
    border: `1px solid ${state.isSelected ? c.orange : c.khaki}`,
    fontWeight: 600,
    fontSize: '12px',
    fontFamily: 'Roboto Mono',
    color: 'c.khaki',
    textTransform: 'uppercase',
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
    color: 'c.khaki',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Roboto Mono',
    textTransform: 'uppercase',
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
});
