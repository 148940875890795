import React, { Fragment } from 'react';

import styles from './reviews.module.scss';
import Button from '../button/button';
import Rating from '../Rating';
import IReview from '../../interfaces/review';

export interface reviewsProps {
  reviews: IReview[];
  cta?: {
    link: string;
    text: string;
  };
}

const Reviews = ({ reviews, cta }: reviewsProps) => {
  return (
    <div className={styles.reviewsGrid}>
      {reviews.map((r, idx) => {
        const actualItem = r?.product_page ?? r?.product ?? r?.accessory;
        return (
          <Fragment key={idx}>
            <div className={styles.reviewMetadata}>
              <div
                className={styles.author}
              >{`${r.customer.first_name} ${r.customer.last_name}`}</div>
              <div className={styles.stars}>
                <Rating averageStars={r.stars} />
              </div>
              <div className={styles.date}>
                {new Date(parseInt(r.created_at) * 1000).toLocaleDateString(
                  'fr-FR',
                )}
              </div>
            </div>
            <div>
              <div className={styles.title}>{r.title}</div>
              <div className={styles.subtitle}>{actualItem?.title_fr}</div>
              <div className={styles.comment}>{r.comment}</div>
              {cta ? (
                <Button content={cta.text} className={styles.buttonStyle} />
              ) : null}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

export default Reviews;
