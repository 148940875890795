import React, { useState } from 'react';
import cn from 'classnames';

import { RichText } from 'prismic-reactjs';
import questionStyles from './index.module.scss';
import styles from '../../components/accordionMenu/accordionMenu.module.scss';
import { linkResolver } from '../../prismic-configuration';
import colours from '../../styles/colours';

const QuestionAnswer = ({ question, answer, color }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={cn(
        styles.accordionMenuWrapper,
        questionStyles['question--wrapper'],
      )}
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={cn(
          styles.accordionMenuTitle,
          { [styles.active]: isOpen },
          questionStyles['question--title'],
        )}
        style={{
          backgroundColor: colours[color],
        }}
      >
        <RichText render={question} linkResolver={linkResolver} />
      </button>
      <div className={cn(questionStyles['question--answer'], 'text')}>
        <RichText render={answer} linkResolver={linkResolver} />
      </div>
    </div>
  );
};

const QuestionAnswers = ({ slice }) => (
  <div className={questionStyles['questions--wrapper']}>
    {slice?.items?.map((item) => (
      <QuestionAnswer
        question={item.question}
        answer={item.answer}
        color={item.color}
      />
    ))}
  </div>
);

export default QuestionAnswers;
