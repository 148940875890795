import React, { useState } from 'react';
import cn from 'classnames';

import { Swiper, SwiperSlide } from 'swiper/react';
import Imgix from 'react-imgix';

import { screenWidth } from '../../styles/variables';
import styles from './index.module.scss';

const IMG_SIZES = `
  (max-width: ${screenWidth.maxScreenLandscape}) 40vw,
  15vw
`;

interface itemProps {
  logo: {
    url: string;
    alt: string;
  };
  logoActive: {
    url: string;
    alt: string;
  };
  review: string;
  reviewer: string;
  journal: string;
}

interface JournalReviewProps {
  item: itemProps;
  isActive: boolean;
}

const JournalReview = ({ item, isActive }: JournalReviewProps) => {
  const src = isActive ? item.logoActive.url ?? item.logo.url : item.logo.url;
  const alt = isActive ? item.logoActive.alt : item.logo.alt;
  return (
    <div className={styles.logoContainer}>
      <Imgix className={styles.logo} src={src} alt={alt} sizes={IMG_SIZES} />
    </div>
  );
};

interface JournalCarouselProps {
  slice: {
    items: itemProps[];
  };
}

const formatAuthor = (reviewer?: string, journal?: string) =>
  `− ${[reviewer, journal].filter(Boolean).join(', ')}`;

const JournalCarousel = ({ slice }: JournalCarouselProps) => {
  const [selectedReview, setSelectedReview] = useState(
    Math.floor((slice.items.length ?? 0) / 2),
  );
  const _onSlideChange = (swiper) => setSelectedReview(swiper.realIndex);

  return (
    <section className={styles.pressContainer}>
      <div className={styles.swiperContainer}>
        <Swiper
          slideToClickedSlide={true}
          initialSlide={selectedReview}
          slidesPerView={1.8}
          centeredSlides={true}
          onSlideChange={_onSlideChange}
          breakpoints={{
            [screenWidth.minScreenSmallValue]: {
              slidesPerView: 5,
            },
          }}
        >
          {slice?.items?.map((item, i) => (
            <SwiperSlide key={item.journal} className={styles['swiper-slide']}>
              <JournalReview item={item} isActive={i === selectedReview} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className={styles['review-wrapper']}>
        {slice?.items?.map((item, index) => (
          <div
            key={item.journal}
            className={cn(styles.review, {
              [styles.displayed]: index === selectedReview,
              ['visibility--hidden']: index !== selectedReview,
            })}
          >
            <q className={styles.quote}>{item.review}</q>
            <div className={styles.author}>
              {formatAuthor(item.reviewer, item.journal)}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default JournalCarousel;
