import React, { ReactElement } from 'react';
import cn from 'classnames';
import { RichText, RichTextBlock } from 'prismic-reactjs';
import { linkResolver } from '../../prismic-configuration';

import styles from './index.module.scss';

interface TitleCompProps {
  slice: {
    primary: {
      title?: RichTextBlock[] | ReactElement | string;
      subtitle?: RichTextBlock[] | ReactElement | string;
      displaySubtitleOnMobile?: boolean;
    };
  };
  isTextInput?: boolean;
}

const TitleComp = ({ slice, isTextInput = false }: TitleCompProps) => (
  <div
    className={cn('padding-top-2em', 'margin-bottom-1em', styles.title__comp)}
  >
    {slice.primary.title ? (
      <div className={cn('page--h2', 'title--line')}>
        {isTextInput ? (
          slice.primary.title
        ) : (
          // @ts-ignore
          <RichText render={slice.primary.title} linkResolver={linkResolver} />
        )}
      </div>
    ) : null}
    {slice.primary.subtitle ? (
      <div
        className={cn('subtitle', 'title--line', {
          'subtitle--display-mobile': slice?.primary?.displaySubtitleOnMobile,
        })}
      >
        {isTextInput ? (
          slice.primary.subtitle
        ) : (
          <RichText
            // @ts-ignore
            render={slice.primary.subtitle}
            linkResolver={linkResolver}
          />
        )}
      </div>
    ) : null}
  </div>
);

export default TitleComp;
