import React from 'react';
import Imgix from 'react-imgix';
import cn from 'classnames';
import { RichText, RichTextBlock } from 'prismic-reactjs';

import { Link } from 'prismic-reactjs';
import { IPrismicImage } from '../../interfaces/prismic/image';
import styles from './index.module.scss';
import { linkResolver } from '../../prismic-configuration';

interface Item {
  image: IPrismicImage;
  text: RichTextBlock[];
  ctaText: string;
  ctaLink: string;
}

interface IFootnoteTextpictureSlice {
  id: string;
  name: string;
  docURL: string;
  version: string;
  description: string;
  items: Item[];
  slice_type: string;
}

interface IFootnoteTextpictureProps {
  slice: IFootnoteTextpictureSlice;
}

const FootnoteTextpicture = ({ slice }: IFootnoteTextpictureProps) => {
  return (
    <div className={styles['footnotes-wrapper']}>
      {slice.items.map((i, idx: number) => (
        <div key={idx} className={styles['footnote']}>
          <Imgix
            className={styles['footnote__img']}
            src={i.image.url}
            alt={i.image.alt}
          />
          <div className={styles['footnote__text']}>
            <RichText render={i.text} linkResolver={linkResolver} />
          </div>

          {!!i.ctaLink && !!i.ctaText ? (
            <a
              href={Link.url(i.ctaLink, linkResolver)}
              className={cn('button--dark', styles['footnote__cta'])}
            >
              {i.ctaText}
            </a>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default FootnoteTextpicture;
