import React, { useRef, useEffect, ChangeEvent } from 'react';
import cn from 'classnames';

import Rating from '../../Rating';
import styles from './index.module.scss';
import { IReview } from '../../../interfaces/review';
import api from '../../../services/api';
import { useAuth } from '../../../contexts/auth';
import { IItemImage } from '../../../interfaces/item_image';
import { getProductImage } from '../../../utils/product';
import { OrderItemTypes } from '../../../contexts/order';

const MARK = 'note globale';
const COMMENTARY = 'commentaires';
const TITLE_PLACEHOLDER =
  'Veuillez saisir ici un titre descriptif de votre commentaire';
const COMMENTARY_PLACEHOLDER = `veuillez décrire ici votre expérience Laboté et votre avis sur votre produit : efficacité, texture, parfum, durée d'utilisation.`;
const SEND_REVIEW = 'Envoyer votre évaluation';
const REMAINING_CHARACTERS = 'Caractères restants';

const MAXIMAL_NUMBER_OF_CHARACTERS = 400;

const adaptHeight = (ref) => {
  if (ref?.current?.style) {
    ref.current.style.height = '0px';
    ref.current.style.height = `${ref.current.scrollHeight + 1}px`;
  }
};

export interface IItemReview {
  id: number;
  itemType: OrderItemTypes;
  images: IItemImage[];
  reviews: IReview;
  title: string;
}

interface AddReviewProps {
  idx: number;
  review: IItemReview;
  setReviews: React.Dispatch<React.SetStateAction<IItemReview[]>>;
}

const AddReviews = ({ review, setReviews, idx }: AddReviewProps) => {
  const { user } = useAuth();
  const { id, images, reviews, itemType, title: productTitle } = review;
  const image = getProductImage(images);

  const { title, comment, stars } = reviews;

  const setReview =
    (field: string) => (e: ChangeEvent<HTMLTextAreaElement>) => {
      const newReview = { ...review };
      newReview.reviews[field] = e?.target?.value ?? e ?? null;
      setReviews((r) =>
        r.map((r, reviewIndex) => (reviewIndex === idx ? newReview : r)),
      );
    };

  const setReviewTitle = setReview('title');
  const setReviewText = setReview('comment');
  const setRating = setReview('stars');
  const reviewTitleRef = useRef(null);
  const reviewCommentRef = useRef(null);

  useEffect(() => {
    adaptHeight(reviewTitleRef);
    adaptHeight(reviewCommentRef);
  });

  const onSubmit = (e) => {
    e.preventDefault();

    // Create a new review
    const payload = {
      stars,
      ...reviews,
      [itemType]: id,
      customer: user.pk,
    };
    api.post('api/customer/reviews/create', payload);
    setReviews((reviews) =>
      reviews.filter((_, reviewIndex) => reviewIndex !== idx),
    );
  };

  return (
    <form onSubmit={onSubmit} className={cn(styles.wrapper)}>
      <div className={cn(styles.description)}>
        <h3 className={cn(styles.title)}>{productTitle}</h3>
        <img className={cn(styles.img)} src={image.picture} alt={image.alt} />
      </div>
      <div className={cn(styles.review)}>
        <span className={cn(styles['review--full-bleed'])}>
          <span className={styles.mark}>{MARK}</span>
          <Rating
            variant="big"
            averageStars={stars}
            withRating
            setRating={setRating}
          />
        </span>
        <span className={cn(styles['review--full-bleed'], styles.commentary)}>
          {COMMENTARY}
        </span>
        <textarea
          ref={reviewTitleRef}
          value={title}
          onChange={setReviewTitle}
          className={cn(styles['review--full-bleed'], styles['text-input'])}
          placeholder={TITLE_PLACEHOLDER}
          maxLength={100}
          required
        />
        <textarea
          ref={reviewCommentRef}
          value={comment}
          onChange={setReviewText}
          className={cn(styles['review--full-bleed'], styles['text-input'])}
          placeholder={COMMENTARY_PLACEHOLDER}
          maxLength={400}
          required
        />
        <div
          className={cn(
            styles['remaining-chars'],
            styles['review--mobile--full-bleed'],
          )}
        >
          {REMAINING_CHARACTERS} :{' '}
          {MAXIMAL_NUMBER_OF_CHARACTERS - comment.length}
        </div>
        <div className={cn(styles.cta, styles['review--mobile--full-bleed'])}>
          <button className="button--dark">{SEND_REVIEW}</button>
        </div>
      </div>
    </form>
  );
};

export default AddReviews;
