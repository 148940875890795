// import DRIP_CHOICE_TAGS from '../../dripTags/choicesTags.json';
// import DRIP_INGREDIENT_TAGS from '../../dripTags/ingredientsTags.json';
// import DRIP_SWAG_SENTENCE_TAGS from '../../dripTags/swagSentencesTags.json';
// import DRIP_SCALE_TAGS from '../../dripTags/scalesTags.json';
import IUser from '../../interfaces/user';

const EVENT_COMPLETED_BEAUTY_PROFILE = 'Completed a beauty profile';
const EVENT_UPDATED_BEAUTY_PROFILE = 'Updated a beauty profile';
const EVENT_ADDED_FULL_FACE_PHOTO = 'Added a full-face photo';
const EVENT_UPDATED_FULL_FACE_PHOTO = 'Updated a full-face photo';
const EVENT_ADDED_CUSTOMER_COMMENT = 'Added a user comment';
const TAG_HAS_FULL_FACE_PHOTO = 'Has Fullface Photo';
const TAG_MAN = 'Man';
const TAG_WOMAN = 'Woman';
const TAG_PROSPECT = 'Prospect';
const TAG_CUSTOMER = 'Customer';

/**
 * Get age given birthdate
 * @param birthdate
 */
const getAge = (birthdate: string) => {
  const today = new Date();
  const birthDate = new Date(birthdate);
  const age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    return age - 1;
  }
  return age;
};

/**
 * Push specific drip event
 * @param email
 * @param event
 * @param payload
 */
export const dripPushEvent = (
  email: string,
  event: string,
  payload: { [key: string]: string } = {},
) => {
  // @ts-ignore
  // _dcq.push([
  //   'track',
  //   event,
  //   {
  //     email: email,
  //     ...payload,
  //     success: (x) => console.log(x),
  //     failure: (x) => console.log(x),
  //   },
  // ]);
};

const TAG_NEWSLETTER = 'Newsletter';
/**
 * Subscribe to newsletter
 * @param email
 */
export const dripSubscribe = (email: string) =>
  // @ts-ignore
  _dcq.push([
    'identify',
    {
      email: email,
      tags: [TAG_NEWSLETTER],
    },
  ]);

export const dripPurchased = (email: string) => {
  // @ts-ignore
  // _dcq.push([
  //   'identify',
  //   {
  //     email: email,
  //     tags: [TAG_CUSTOMER],
  //     remove_tags: [TAG_PROSPECT],
  //   },
  // ]);
  return;
};

const TAG_HAS_BEAUTY_PROFILE = 'Has Beauty Profile';
/**
 * Propagate quizz changes to drip
 * @param previousUser
 * @param user
 * @param choices
 */
export const dripUpdateQuizz = (previousUser: IUser, user: IUser, choices) => {
  return;
};
