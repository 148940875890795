import React from 'react';
import styles from './index.module.scss';

const formatBulletPoint = (i: number) => String(i).padStart(2, '0');

const MySlice = ({ slice }) => (
  <ul className={styles.bulletPointList}>
    {slice?.items?.map(({ entry }, idx) => (
      <li key={idx} className={styles.bulletPoint}>
        <span>{formatBulletPoint(idx + 1)}</span>
        <span>{entry}</span>
      </li>
    ))}
  </ul>
);

export default MySlice;
