import React from 'react';
import { RichText } from 'prismic-reactjs';
import styles from './index.module.scss';
import cn from 'classnames';
const NUMBER_OF_REPEAT = 5;

interface MarqueeHeaderProps {
  slice: { items: string[] };
}

const MarqueeHeader = ({ slice }: MarqueeHeaderProps) => {
  const items = Array(NUMBER_OF_REPEAT)
    .fill(slice?.items ?? [])
    .flat();

  return (
    <div className={cn(styles.marquee, 'full-bleed')}>
      <div className={styles.track}>
        <div className={styles.content}>
          {items?.map((item, i) => (
            <span className={styles.element} key={`item.text-${i}`}>
              {item.text}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MarqueeHeader;
