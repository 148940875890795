import React, { ReactElement, useState } from 'react';
import cn from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade } from 'swiper/core';
import 'swiper/swiper.min.css';
import 'swiper/components/effect-fade/effect-fade.min.css';
import ReactGA from 'react-ga4';

import { RichText, Link, RichTextBlock } from 'prismic-reactjs';
import { Background } from 'react-imgix';

import { linkResolver } from '../../prismic-configuration';
import styles from './index.module.scss';

SwiperCore.use([EffectFade]);

const IMG_SIZES = '100vw';

const formatCounter = (i: number) =>
  i.toLocaleString('fr-Fr', { minimumIntegerDigits: 2 });

interface HeaderCarouselItem {
  image: {
    url: string;
    alt: string;
  };
  title: RichTextBlock[] | string | ReactElement;
  text: RichTextBlock[] | string;
  textColor: string;
  ctaText: string;
  ctaLink: Link;
  contentPosition: string;
  notFromPrismic: boolean;
  isViewResult?: boolean;
}

interface HeaderCarouselSlice {
  items: HeaderCarouselItem[];
}

interface HeaderCarouselProps {
  slice: HeaderCarouselSlice;
}

const HeaderCarousel = ({ slice }: HeaderCarouselProps) => {
  const [selectedImage, setSelectedImage] = useState(0);
  const displayImageCounter = slice?.items?.length > 1;

  const [swiper, setSwiper] = useState(null);

  const slideTo = (index) => {
    swiper.slideTo(index);
    setSelectedImage(index);
  };

  const handleViewResultGASend = () => {
    ReactGA.event('view_result');
  };

  return (
    <section className={cn(styles.crossFading, 'full-bleed')}>
      <Swiper onSwiper={setSwiper} effect="fade">
        {slice?.items?.map((item, i) => (
          <SwiperSlide key={i}>
            <Background
              key={i}
              src={item.image.url}
              alt={item.image.alt}
              sizes={IMG_SIZES}
              className={cn(styles.image)}
              imgixParams={{ crop: 'entropy' }}
            >
              <div
                className={cn(styles.content, {
                  [styles.contentOnRight]:
                    item.contentPosition === 'contentOnRight',
                })}
              >
                {item.title ? (
                  <div
                    className={cn(
                      'margin-top-30',
                      styles.title,
                      styles[item.textColor],
                    )}
                  >
                    {item?.notFromPrismic ? (
                      item.title
                    ) : (
                      <RichText
                        // @ts-ignore
                        render={item.title}
                        linkResolver={linkResolver}
                      />
                    )}
                  </div>
                ) : null}
                <div
                  className={cn(
                    styles.text,
                    styles[item.textColor],
                    'abril--line',
                  )}
                >
                  {typeof item.text === 'string' ? (
                    <div>{item.text}</div>
                  ) : (
                    <RichText render={item.text} linkResolver={linkResolver} />
                  )}
                </div>
                {!!item.ctaLink && !!item.ctaText ? (
                  <a
                    href={Link.url(item.ctaLink, linkResolver)}
                    onClick={() => {
                      if (item?.isViewResult) handleViewResultGASend();
                    }}
                    id="headerCarouselButton"
                    className={cn(
                      'margin-bottom-30',
                      'button--dark',
                      'button--light',
                      'button--h-padding-24',
                    )}
                  >
                    {item.ctaText}
                  </a>
                ) : null}
              </div>
            </Background>
          </SwiperSlide>
        ))}
      </Swiper>

      {displayImageCounter ? (
        <div className={styles.crossFadingCounters}>
          {slice?.items?.map((_, i) => {
            const isDisplayed = selectedImage === i;
            return (
              <span
                className={cn(styles.counter, {
                  [styles.displayed]: isDisplayed,
                })}
                key={i}
                onClick={() => slideTo(i)}
              >
                {formatCounter(i + 1)}
              </span>
            );
          })}
        </div>
      ) : null}
    </section>
  );
};

export default HeaderCarousel;
