const colours = {
  // Colours
  // Primary colour
  black: '#292928',
  white: '#f7f2f0',
  // Secondary colour
  green: '#8b9b87', // water green
  khaki: ' #7f8f83',
  grey: '#d5d4d2',
  // Tertiary colours
  orange: '#dd794b', // orange terracotta
  orange_opacity_30: 'rgb(221, 121, 75, 0.3)',
  platinium: '#ede6e2',
  pale_silver: '#d2c6b8',
  spun_pearl: ' #9ca99f',
  swirl: '#d4c9c2',
};

export default colours;
