import React from 'react';
import cn from 'classnames';
import Imgix, { Picture, Source } from 'react-imgix';
import Link from 'next/link';

import { IPrismicImage } from '../../interfaces/prismic/image';

import styles from './index.module.scss';
import { linkResolver } from '../../prismic-configuration';
import { screenWidth } from '../../styles/variables';

const IMGIX_SIZES = `
  (max-width: ${screenWidth.maxScreenLandscape}) 100vw,
  (max-width: ${screenWidth.maxScreenSmall}) 50vw,
  33vw
`;

export interface BlogpostListSlice {
  slice_type: string;
  slice_label: null;
  items: BlogpostProps[];
  primary: Primary;
}

export interface BlogpostProps {
  post: Post;
}

export interface Post {
  id: string;
  type: string;
  tags: string[];
  slug: string;
  lang: string;
  uid: string;
  data: Data;
  link_type: string;
  isBroken: boolean;
}

export interface Data {
  uid: string;
  publishDate: string;
  meta_title: string;
  social_cards: SocialCard[];
}

export interface SocialCard {
  social_card_image: IPrismicImage;
  social_card_title: string;
  social_card_description: string;
}

export interface Primary {}

interface BlogpostListProps {
  slice: BlogpostListSlice;
}

const Blogpost = ({ post }: BlogpostProps) => {
  const socialCard = post.data.social_cards?.[0];
  const {
    social_card_image: image,
    social_card_title: title,
    social_card_description: description,
  } = socialCard;

  const blogpostLink = linkResolver(post);

  return (
    <div className={styles['blogpost-wrapper']}>
      {image?.url || image?.alt ? (
        <Link href={blogpostLink}>
          <a>
            <Imgix
              className={styles.blogpost__image}
              sizes={IMGIX_SIZES}
              src={image?.url}
              alt={image?.alt}
              imgixParams={{ ar: '1:1', fit: 'crop' }}
            />
          </a>
        </Link>
      ) : null}
      <div className={styles.blogpost__description}>
        <span
          className={cn('roboto--line', styles['blogpost__description--date'])}
        >
          {new Date(post.data.publishDate).toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </span>
        <span
          className={cn('title--line', styles['blogpost__description--title'])}
        >
          <Link href={blogpostLink}>
            <a>{title}</a>
          </Link>
        </span>
        <span
          className={cn(
            'text',
            'abril--line',
            styles['blogpost__description--description'],
          )}
        >
          {description}
        </span>
        <Link href={blogpostLink}>
          <a className={styles.blogpost__link}>
            <img src="/svg/right_arrow.svg" alt="Right arrow"></img>
          </a>
        </Link>
      </div>
    </div>
  );
};

// CAUTION : This slice can’t be updated with slice machine because it use integration field
const BlogpostList = ({ slice }: BlogpostListProps) => {
  return (
    <div className={styles['blogposts-wrapper']}>
      {slice.items.map((i) => (
        <Blogpost post={i.post} />
      ))}
    </div>
  );
};

export default BlogpostList;
