import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import IProduct from '../../../interfaces/product';
import { useGuide } from '../../../contexts/guide';
import addToCartStyles from './index.module.scss';
import styles from '../productDisplay.module.scss';

import { TEXTURE_AND_PERFUME_GUIDE } from '../../TextureAndPerfumeGuide';
import { getDefaultWeightOption } from '../../../utils/product';
import { IProductPageDetailledProduct } from '../../../interfaces/productPage';
import { OrderItemTypes, useOrder } from '../../../contexts/order';
import useCustomizationOptions from '../../../hooks/customization';
import { getTitle, orderItemAddToCartGTM } from '../../../utils/order_item';

import ProductCustomization from '../../ProductCustomization/index.module';
import {
  CHOOSE_YOUR_PERFUME,
  CHOOSE_YOUR_TEXTURE,
} from '../../ProductCustomization/constants';

const PERSONNALISATION = 'Personnalisez votre produit';
const ONLY_WEIGHT_CHANGE_PRICE = 'Seule la contenance modifie le prix';
const CONFIRM = 'VALIDER';

interface AddToCartProps {
  product?: IProduct;
  productPage?: IProductPageDetailledProduct;
  handleExit: () => void;
  setAddedToCart: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddToCart = ({
  product: customProduct,
  productPage,
  handleExit,
  setAddedToCart,
}: AddToCartProps) => {
  const product: IProduct = customProduct ?? productPage.product;
  const orderItemType: string = productPage
    ? OrderItemTypes.PRODUCT_PAGE
    : OrderItemTypes.PRODUCT;

  const { addOrderItem } = useOrder();

  const [contenance, setContenance] = useState(
    getDefaultWeightOption(product, productPage),
  );
  const currentPrice = product.weights.find(
    (x) => x.id === contenance.value,
  )?.price;
  const [texture, setTexture] = useState(null);
  const [perfume, setPerfume] = useState(null);

  const {
    weightsOptions,
    texturesOptions,
    perfumesOptions,
    maxNumberOfWeightsOptions,
    maxNumberOfTexturesOptions,
    maxNumberOfPerfumesOptions,
  } = useCustomizationOptions(
    product,
    productPage,
    contenance?.value,
    texture?.value,
    setContenance,
    setTexture,
    setPerfume,
  );

  const addItem = () => {
    const variant = contenance ?? weightsOptions?.[0];
    const reference = texture ?? texturesOptions?.[0];
    const ingredient_perfume = perfume ?? perfumesOptions?.[0];
    const payload = {
      variant: variant?.value,
      reference: reference?.value,
      ingredient_perfume: ingredient_perfume?.value,
    };
    orderItemAddToCartGTM(
      getTitle(product, productPage, null),
      variant?.label,
      reference?.label,
      ingredient_perfume?.label,
      variant?.price,
    );
    addOrderItem(productPage ?? product, orderItemType, payload);
    setAddedToCart(true);
    handleExit();
  };

  useEffect(() => {
    if (
      maxNumberOfWeightsOptions === 1 &&
      maxNumberOfTexturesOptions === 1 &&
      maxNumberOfPerfumesOptions === 1
    ) {
      addItem();
    }
  }, [addItem]);

  const { displayGuide, setDisplayGuide } = useGuide();

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    addItem();
  };

  return (
    <form
      onSubmit={onSubmit}
      className={cn(
        addToCartStyles['add-to-cart__wrapper'],
        styles['product__wrapper--clicked'],
        { 'display--none': displayGuide },
      )}
    >
      <section className={addToCartStyles['section-wrapper']}>
        <div onClick={handleExit} className="exit-button" />
        <section className={styles.productHeader}>
          <div className={styles.personnalisation__title}>
            {PERSONNALISATION}
          </div>
          <div className={styles.personnalisation__subtitle}>
            {ONLY_WEIGHT_CHANGE_PRICE}
          </div>
        </section>
        <div
          id="product-form"
          className={cn(
            addToCartStyles['selects-wrapper'],
            'swiper-no-swiping',
          )}
        >
          <ProductCustomization
            options={weightsOptions}
            maxNumberOfOptions={maxNumberOfWeightsOptions}
            value={contenance}
            setValue={setContenance}
            placeholder="Format"
          />
          <ProductCustomization
            options={texturesOptions}
            maxNumberOfOptions={maxNumberOfTexturesOptions}
            value={texture}
            setValue={setTexture}
            placeholder={CHOOSE_YOUR_TEXTURE}
          />
          <ProductCustomization
            options={perfumesOptions}
            maxNumberOfOptions={maxNumberOfPerfumesOptions}
            value={perfume}
            setValue={setPerfume}
            placeholder={CHOOSE_YOUR_PERFUME}
          />
          <div
            onClick={() => setDisplayGuide(true)}
            className={styles.personnalisation__link}
          >
            {TEXTURE_AND_PERFUME_GUIDE}
          </div>
        </div>
        <section className={styles.productFooter}>
          <div className={styles['buttons-wrapper']}>
            <button
              type="submit"
              className={cn(
                'button--dark',
                'swiper-no-swiping',
                styles['button-full-width'],
              )}
            >{`${CONFIRM} | ${currentPrice} €`}</button>
          </div>
        </section>
      </section>
    </form>
  );
};

export default AddToCart;
