// Screen sizes
// Large desktops and laptops

const MIN_SCREEN_SMALL_VALUE = 768;

export const screenWidth = {
  minScreenLarge: '1200px',

  // Landscape tablets and medium desktops
  maxScreenMedium: '1199px',
  minScreenMedium: '992px',

  // Portrait tablets and small desktops
  minScreenSmallValue: MIN_SCREEN_SMALL_VALUE,
  minScreenSmall: `${MIN_SCREEN_SMALL_VALUE}px`,
  maxScreenSmall: '991px',

  // Landscape phones and portrait tablets
  maxScreenLandscape: '767px',
  minScreenLandscape: '481px',

  // Portrait phones and smaller
  maxScreenPortrait: '480px',
};
