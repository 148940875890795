import React, { useEffect, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper/core';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

import styles from './index.module.scss';
import Reviews from '../reviews';
import IReview from '../../../interfaces/review';

SwiperCore.use([Pagination]);

const MOBILE_PAGE_SIZE = 1;
const PAGE_SIZE = 3;

const formatPageNumber = (i: number) => String(i).padStart(2, '0');

interface ReviewCarouselProps {
  reviewData: IReview[];
}

const ReviewsCarousel = ({ reviewData }: ReviewCarouselProps) => {
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  useEffect(() => {
    setPageSize(window.innerWidth < 768 ? MOBILE_PAGE_SIZE : PAGE_SIZE);
  }, []);

  const pagination = {
    dynamicBullets: true,
    clickable: true,
    renderBullet: function (index, className) {
      return `<span class="${className}">${formatPageNumber(index + 1)}</span>`;
    },
  };

  const reducer = (acc, current) => {
    if (acc[acc.length - 1].length < pageSize) {
      acc[acc.length - 1].push(current);
    } else {
      acc.push([current]);
    }
    return acc;
  };

  if (reviewData) {
    const groupedReviews = reviewData.reduce(reducer, [[]]);

    return (
      <div className={styles['carousel-wrapper']}>
        <Swiper pagination={pagination}>
          {groupedReviews.map((groupedReview, i) => {
            return (
              <SwiperSlide key={i}>
                <Reviews reviews={groupedReview} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    );
  } else {
    return null;
  }
};

export default ReviewsCarousel;
