import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import cn from 'classnames';

import IReview from '../../interfaces/review';
import Reviews from '../../components/reviews/reviews';
import { AxiosResponse } from 'axios';

const PAGE_SIZE = 5;
const MORE_REVIEWS = 'Voir plus d’avis';

const AllReview = () => {
  const [nextPage, setNextPage] = useState<string>(null);
  const [reviews, setReviews] = useState<IReview[]>([]);

  const handleNextPage = (response: AxiosResponse<any>) => {
    const { results, next } = response.data;
    setNextPage(next);
    setReviews((reviews) => [...reviews, ...results]);
  };

  useEffect(() => {
    api
      .get(`api/reviews/?page=${1}&page_size=${PAGE_SIZE}`)
      .then(handleNextPage);
  }, []);

  const loadMoreReviews = () => api.get(nextPage).then(handleNextPage);

  return (
    <div className="margin-bottom-30 margin-top-30">
      <div className="margin-bottom-30">
        <Reviews reviews={reviews} />
      </div>
      <button
        onClick={loadMoreReviews}
        className={cn('button--dark', {
          'display--none': !nextPage,
        })}
      >
        {MORE_REVIEWS}
      </button>
    </div>
  );
};

export default AllReview;
