import React, { Dispatch, useEffect, useState } from 'react';
import cn from 'classnames';
import Imgix from 'react-imgix';
import NextLink from 'next/link';

import { Link } from 'prismic-reactjs';

import { linkResolver } from '../../prismic-configuration';
import styles from './index.module.scss';
import { urls } from '../../utils/urls';
import { useAuth } from '../../contexts/auth';

interface Item {
  title: string;
  link: Link;
  level: string;
}

interface Dimensions {
  width: number;
  height: number;
}

interface Image {
  dimensions: Dimensions;
  alt: string;
  copyright: null;
  url: string;
}

interface Primary {
  title: string;
  link: Link;
  image: Image;
  imageTitle: string;
  imageDescription: string;
}

interface NavigationTabSlice {
  id: string;
  name: string;
  docURL: string;
  version: string;
  description: string;
  primary: Primary;
  items: Item[];
  slice_type: string;
  activeTab: number;
  setActiveTab: React.Dispatch<number>;
  isFromFooter?: boolean;
  isLast?: boolean;
}

interface NavigationTabProps {
  slice: NavigationTabSlice;
  i: number;
}

const buildTabTree = (items) => {
  const tabTreeReducer = (acc, item) => {
    if (acc[acc.length - 1] === undefined || parseInt(item.level) === 2) {
      acc.push([item]);
    } else {
      acc[acc.length - 1].push(item);
    }
    return acc;
  };
  return items.reduce(tabTreeReducer, []);
};

const OptionnalLink = ({ link, children }) => {
  if (link?.url || link?.uid || link?.id) {
    return (
      <a
        className={styles.navigation__link}
        href={Link.url(link, linkResolver)}
      >
        {children}
      </a>
    );
  } else {
    return children;
  }
};

const DIAGNOSTIC = 'Mettre à jour mon diagnostic';
const MY_RESULTS = 'Mes résultats';
const MY_PRODUCTS = 'Mon protocole';
const MY_ORDERS = 'Mon historique de commandes';
const ADD_A_REVIEW = 'Ajouter un avis';
const DISCONNECT = 'Déconnexion';
const SPONSORING = 'Parrainage';

interface NavigationLink {
  title: string;
  href: string;
  isDisplayed: boolean;
}

interface NavigationTabUserProps {
  activeTab: number;
  setActiveTab: Dispatch<Number>;
  i: number;
  swagSentenceToProtocol: {
    swagSentence: { id: number };
    protocol: Link;
  }[];
}
export const NavigationTabUser = ({
  activeTab,
  setActiveTab,
  i,
  swagSentenceToProtocol,
}: NavigationTabUserProps) => {
  const { user, isAuthenticated, isDiagnosticDone, logout } = useAuth();
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [active, setActive] = useState(false);

  const protocolLink = swagSentenceToProtocol.find(
    (e) => e.swagSentence.id === user?.swag_sentence?.id,
  )?.protocol;

  const handleMouseEnter = () => setIsMouseOver(true);
  const handleMouseLeave = () => setIsMouseOver(false);

  useEffect(() => {
    if (activeTab === null) {
      setActive(false);
    }
  }, [activeTab]);

  const _handleClick = () => {
    setActive(!active);
    setActiveTab?.(active ? null : i);
  };

  const links: NavigationLink[] = [
    { title: DIAGNOSTIC, href: urls.START_QUIZZ, isDisplayed: true },
    { title: MY_RESULTS, href: urls.RESULT, isDisplayed: isDiagnosticDone },
    {
      title: MY_PRODUCTS,
      href: isDiagnosticDone && protocolLink ? linkResolver(protocolLink) : '/',
      isDisplayed: isDiagnosticDone,
    },
    { title: MY_ORDERS, href: urls.COMMANDS, isDisplayed: true },
    { title: ADD_A_REVIEW, href: urls.ADD_A_REVIEW, isDisplayed: true },
    { title: SPONSORING, href: urls.SPONSORING, isDisplayed: true },
  ];

  if (!isAuthenticated) {
    return null;
  }
  return (
    <section
      className={cn(styles.tab, {
        [styles.inactive]: activeTab && activeTab !== i,
      })}
      onClick={_handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img
        src={`/svg/user_icon${isMouseOver ? '_active' : ''}.svg`}
        alt={'User menu'}
      />
      <div
        className={cn(styles['tab-links-wrapper'], styles.tab__user, {
          [styles.active__desktop]: isMouseOver,
          [styles.active__mobile]: active,
        })}
      >
        <div className={styles.filler__width} />
        <div className={cn(styles.navigation__menu)}>
          <span
            className={cn(
              styles['navigation__title--second-level'],
              styles['navigation__title--third-level--orange'],
              styles['tab__user--username'],
            )}
          >
            <div className={styles['tab__user--userinfo']}>
              <div>{user.first_name}</div>
              <div>{user.email}</div>
            </div>
          </span>
          {links.map((l, idx) =>
            l.isDisplayed ? (
              <NextLink href={l.href} key={idx}>
                <a
                  className={cn(
                    styles.navigation__link,
                    styles['navigation__title--third-level'],
                  )}
                >
                  {l.title}
                </a>
              </NextLink>
            ) : null,
          )}
        </div>
        <span
          onClick={logout}
          className={cn(
            styles.navigation__link,
            styles.disconnect__button,
            styles['navigation__title--second-level'],
            styles['tab__user--disconnect'],
          )}
        >
          {DISCONNECT}
        </span>
      </div>
    </section>
  );
};

const NavigationTab = ({ slice, i }: NavigationTabProps) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [active, setActive] = useState(false);
  const { isDiagnosticDone } = useAuth();

  const handleMouseEnter = () => setIsMouseOver(true);
  const handleMouseLeave = () => setIsMouseOver(false);

  const tabIsALink =
    slice.primary.link?.url ||
    slice.primary.link?.id ||
    slice.primary.link?.uid;

  const _handleClick = () => {
    if (!tabIsALink) {
      setActive(!active);
      slice?.setActiveTab?.(active ? null : i);
    }
  };

  const tabTree = buildTabTree(slice.items);

  const activeTab = slice?.activeTab;

  useEffect(() => {
    if (activeTab === null) {
      setActive(false);
    }
  }, [activeTab]);

  if (slice?.isLast && isDiagnosticDone) {
    return null;
  }
  return (
    <section
      className={cn(styles.tab, {
        [styles.inactive]: slice?.activeTab !== null && slice?.activeTab !== i,
        [styles['display--footer']]: slice?.isFromFooter,
        [styles['last--section']]: slice?.isLast,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <OptionnalLink link={slice.primary.link}>
        <span
          className={cn(styles['navigation__title--first-level'], {
            [styles.active]: active,
            [styles.link]: tabIsALink,
            [styles['last-section__title']]: slice?.isLast,
          })}
          onClick={_handleClick}
        >
          {slice.primary.title}
        </span>
      </OptionnalLink>
      <div
        className={cn(styles['tab-links-wrapper'], {
          [styles.active__desktop]:
            isMouseOver && (tabTree.length > 0 || slice.primary.image.url),
          [styles.active__mobile]: active,
        })}
      >
        <div className={styles.filler__width} />
        {tabTree.map((subMenu: Item[], idx: number) => (
          <div key={idx} className={styles.navigation__menu}>
            {subMenu.map((item: Item, linkIdx: number) => (
              <OptionnalLink key={`${item.title}${linkIdx}`} link={item.link}>
                <span
                  className={cn({
                    [styles['navigation__title--second-level']]:
                      parseInt(item.level) === 2,
                    [styles['navigation__title--third-level']]:
                      parseInt(item.level) === 3,
                  })}
                >
                  {item.title}
                </span>
              </OptionnalLink>
            ))}
          </div>
        ))}
        {slice.primary.image.url ? (
          <div className={styles['navigation__image--wrapper']}>
            <Imgix
              alt={slice.primary.image.alt}
              src={slice.primary.image.url}
              width={200}
              height={200}
              className={styles.navigation__image}
            />
            <span className={styles['navigation__image--title']}>
              {slice.primary.imageTitle}
            </span>
            <span className={styles['navigation__image--description']}>
              {slice.primary.imageDescription}
            </span>
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default NavigationTab;
