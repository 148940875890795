import React from 'react';
import cn from 'classnames';

import { RichText } from 'prismic-reactjs';
import { linkResolverFromRouter } from '../../prismic-configuration';

import styles from './index.module.scss';
import { useRouter } from 'next/router';

const TextBlog = ({ slice }) => {
  const router = useRouter();
  return (
    <div className={cn('text', 'abril--line', styles['text-blog'])}>
      <RichText
        render={slice.primary.text}
        linkResolver={linkResolverFromRouter(router)}
      />
    </div>
  );
};

export default TextBlog;
