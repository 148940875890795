import React from 'react';
import cn from 'classnames';

import { RichText } from 'prismic-reactjs';
import { linkResolverFromRouter } from '../../prismic-configuration';
import { useRouter } from 'next/router';

const TextComp = ({ slice }) => {
  const router = useRouter();
  return (
    <div className={cn('text', 'abril--line')}>
      <RichText
        render={slice.primary.text}
        linkResolver={linkResolverFromRouter(router)}
      />
    </div>
  );
};

export default TextComp;
