import React, { useEffect, useState, Fragment } from 'react';
import cn from 'classnames';

import { IItemReview } from '../../components/reviews/AddReview';
import api from '../../services/api';
import AddReview from '../../components/reviews/AddReview';

import styles from './index.module.scss';
import { getProductType } from '../../utils/order_item';
const NO_PRODUCT_TO_EVALUATE =
  'Vous n’avez pas de produit à évaluer pour le moment.';

const buildDefaultReview = (item: IItemReview) => {
  return {
    title: '',
    comment: '',
    stars: 0,
  };
};

const MySlice = () => {
  const [reviews, setReviews] = useState<IItemReview[]>([]);

  useEffect(() => {
    api
      .get('api/customer/reviews')
      .then((response) => response.data)
      .then((data) => {
        return data.flatMap((d) => {
          const item = d?.product_page ?? d?.product ?? d?.accessory;
          const itemType = getProductType(
            d?.product,
            d?.product_page,
            d?.accessory,
          );
          if (item.reviews[0]) {
            return [];
          }
          item.reviews = buildDefaultReview(item);
          return {
            itemType,
            ...item,
            title: item.title ?? item.title_fr,
          };
        });
      })
      .then((d) => setReviews(d));
  }, []);
  return reviews.length > 0 ? (
    <div className={styles.reviews}>
      {reviews.map((review, idx) => (
        <Fragment key={`${idx} ${review.title}`}>
          <AddReview idx={idx} review={review} setReviews={setReviews} />
          {idx < reviews.length - 1 ? (
            <div className={styles.reviews__separator}></div>
          ) : null}
        </Fragment>
      ))}
    </div>
  ) : (
    <div className={cn('text', 'abril--line', 'margin-bottom-30')}>
      {NO_PRODUCT_TO_EVALUATE}
    </div>
  );
};

export default MySlice;
