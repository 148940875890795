import React from 'react';

import SliceZone from 'next-slicezone';

import resolver from '../../sm-resolver.js';
import { FooterMenuSlice } from '../../slices/FooterMenu/index';
import { FooterNewsletterSlice } from '../../slices/FooterNewsletter/index';
import { FooterFollowSlice } from '../../slices/FooterFollow/index';
import styles from './footerBar.module.scss';

export interface FooterBarProps {
  footer: FooterData;
}

export interface FooterData {
  id: string;
  uid: null;
  url: null;
  type: string;
  href: string;
  tags: any[];
  first_publication_date: string;
  last_publication_date: string;
  slugs: string[];
  linked_documents: any[];
  lang: string;
  alternate_languages: any[];
  data: Data;
}

export interface Data {
  body: (FooterMenuSlice | FooterNewsletterSlice | FooterFollowSlice)[];
}

export const FooterBarInHeader = ({ footer }: FooterBarProps) => {
  return (
    <>
      <SliceZone
        slices={footer.data.body
          .slice(-1)
          .map((x) => ({ ...x, inHeader: true }))}
        resolver={resolver}
      />
      <div className={styles.footer__copyright}>
        Droit d'auteur © 2021, <span className="underline">Laboté</span>.
      </div>
    </>
  );
};

const CREDIT_CARD: { src: string; alt: string }[] = [
  {
    alt: 'Amex icon',
    src: '/svg/amex_icon.svg',
  },
  {
    alt: 'Apple pay icon',
    src: '/svg/apple_pay_icon.svg',
  },
  {
    alt: 'Mastercard icon',
    src: '/svg/mastercard_icon.svg',
  },
  {
    alt: 'Visa icon',
    src: '/svg/visa_icon.svg',
  },
];

const FooterBar = ({ footer }: FooterBarProps) => {
  return (
    <footer className={styles.footerWrapper}>
      <div className={styles.footerSliceWrapper}>
        <SliceZone slices={footer.data.body} resolver={resolver} />
      </div>
      <div className={styles.footer__informations}>
        <div className={styles.footer__copyright}>
          Droit d'auteur © 2021, <span className="underline">Laboté</span>.
        </div>
        <div className={styles['footer__payments--wrapper']}>
          {CREDIT_CARD.map(({ src, alt }, i) => (
            <img
              key={i}
              width={40}
              height={20}
              alt={alt}
              src={src}
              className={styles.footer__payment}
            />
          ))}
        </div>
      </div>
    </footer>
  );
};

export default FooterBar;
