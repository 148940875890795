import React, { useEffect, useState, useRef } from 'react';
import styles from './index.module.scss';
import cn from 'classnames';

const getSrc = (i: number, averageStars: number) =>
  i < averageStars ? '/svg/filled-star.svg' : '/svg/star.svg';
const getAlt = (i: number, averageStars: number) =>
  i < averageStars ? 'filled-star' : 'star';

interface RatingProps {
  averageStars: number;
  maxNumberOfStars?: number;
  reviewCount?: number;
  variant?: 'big' | undefined;
  withRating?: boolean;
  setRating?: (e: any) => void;
}
const Rating = ({
  averageStars,
  maxNumberOfStars = 5,
  reviewCount = null,
  variant,
  withRating = false,
  setRating,
}: RatingProps) => {
  const [numberOfStars, setNumberOfStars] = useState<number>(averageStars);
  const onMouseEnterStar = (i: number) => () => {
    if (withRating) {
      setNumberOfStars(i + 1);
    }
  };
  const onMouseLeave = () => {
    if (withRating) {
      setNumberOfStars(averageStars);
    }
  };
  const handleClick = () => {
    if (withRating) {
      setRating(numberOfStars);
    }
  };

  return (
    <div
      className={cn(styles['rating-wrapper'], {
        [styles['with-rating']]: withRating,
      })}
    >
      {Array.from(Array(maxNumberOfStars)).map((_, i) => (
        <img
          onMouseEnter={onMouseEnterStar(i)}
          onMouseLeave={onMouseLeave}
          onClick={handleClick}
          key={i}
          src={getSrc(i, numberOfStars)}
          alt={getAlt(i, numberOfStars)}
          className={cn({ [styles[variant]]: true })}
        />
      ))}
      {Number.isInteger(reviewCount) ? (
        <div
          className={cn(styles['rating--review-count'], 'swiper-no-swiping', {
            [styles[variant]]: true,
          })}
        >
          {reviewCount}
        </div>
      ) : null}
      {withRating ? <input className="input--hidden" type="number" value={numberOfStars} min={1} max={5}/> : null}
    </div>
  );
};

export default Rating;
