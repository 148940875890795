import React, { useState } from 'react';
import cn from 'classnames';

import { Link } from 'prismic-reactjs';

import { linkResolver } from '../../prismic-configuration';
import styles from './index.module.scss';

interface FooterMenuProps {
  slice: FooterMenuSlice;
}

export interface FooterMenuSlice {
  id: string;
  name: string;
  docURL: string;
  version: string;
  description: string;
  primary: Primary;
  items: Item[];
  slice_type: string;
}

interface Item {
  secondLevelTitle: string;
  link: Link;
}

interface Primary {
  firstLevelTitle: string;
}

const FooterMenu = ({ slice }: FooterMenuProps) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <section className={styles.footerMenuWrapper}>
      <div
        onClick={() => setIsActive(!isActive)}
        className={cn(styles.footerMenuTitle, { [styles.active]: isActive })}
      >
        {slice.primary.firstLevelTitle}
      </div>
      <div className={styles.menuBody}>
        {slice.items.map((i, idx) => (
          <a
            key={idx}
            href={Link.url(i.link, linkResolver)}
            className={styles.footerMenuSubtitle}
          >
            {i.secondLevelTitle}
          </a>
        ))}
      </div>
    </section>
  );
};

export default FooterMenu;
