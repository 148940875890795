import React from 'react';
import Select from 'react-select';

import { productSelectStyle } from '../productDisplay/utils';

interface ProductCustomizationProps {
  options: { label: string; value: number }[];
  maxNumberOfOptions: number;
  value: { label: string; value: number };
  setValue: any;
  placeholder: string;
}

const ProductCustomization = ({
  options,
  maxNumberOfOptions,
  value,
  setValue,
  placeholder,
}: ProductCustomizationProps) =>
  maxNumberOfOptions > 1 ? (
    <div className="hidden-select-wrapper">
      <Select
        styles={productSelectStyle(value)}
        options={options}
        components={{
          IndicatorSeparator: () => null,
        }}
        placeholder={placeholder}
        value={value ?? ''}
        onChange={setValue}
      />
      <input className="hidden-select" value={value?.label ?? ''} required />
    </div>
  ) : null;

export default ProductCustomization;
