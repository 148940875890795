import React from 'react';
import styles from './index.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import { Link } from 'prismic-reactjs';
import { linkResolver } from '../../prismic-configuration';

SwiperCore.use([Autoplay]);

interface BannerHeaderSlice {
  slice_type: string;
  slice_label: null;
  version: string;
  variation: string;
  primary: Primary;
  items: Item[];
}

interface Primary {}

interface Item {
  title: string;
  link: Link;
}

interface BannerHeaderProps {
  slice: BannerHeaderSlice;
}

const BannerHeader = ({ slice }: BannerHeaderProps) => {
  if (!slice) {
    return null;
  }
  return (
    <div className={styles['banner-header__wrapper']}>
      <Swiper
        autoplay={{
          delay: 5000,
        }}
        spaceBetween={50}
        slidesPerView={1}
        // allowTouchMove={false}
      >
        {slice?.items?.map((item, i) => (
          <SwiperSlide key={i}>
            <div className={styles['banner-header__text']}>
              {item?.link?.url || item?.link?.uid ? (
                <a href={Link.url(item.link, linkResolver)}>{item.title}</a>
              ) : (
                item.title
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default BannerHeader;
