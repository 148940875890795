import React from 'react';
import cn from 'classnames';
import { Link } from 'prismic-reactjs';
import Imgix from 'react-imgix';

import styles from './index.module.scss';
import { linkResolver } from '../../prismic-configuration';

interface FooterFollowProps {
  slice: FooterFollowSlice;
}

export interface FooterFollowSlice {
  id: string;
  name: string;
  docURL: string;
  version: string;
  description: string;
  primary: Primary;
  items: Item[];
  slice_type: string;
  inHeader: boolean;
}

interface Primary {
  firstLevelTitle: string;
}

interface Item {
  websiteIcon: WebsiteIcon;
  websiteLink: Link;
}

interface WebsiteIcon {
  dimensions: Dimensions;
  alt: string;
  copyright: null;
  url: string;
}

interface Dimensions {
  width: number;
  height: number;
}

const FooterFollow = ({ slice }: FooterFollowProps) => {
  return (
    <section
      className={cn(styles.footerFollowWrapper, {
        [styles['in-header']]: slice.inHeader,
      })}
    >
      <div
        className={cn(styles.footerFollowTitle, {
          [styles['in-header']]: slice.inHeader,
        })}
      >
        {slice.primary.firstLevelTitle}
      </div>
      <div className={styles.iconsWrapper}>
        {slice.items.map((item, idx) => (
          <a key={idx} href={Link.url(item.websiteLink, linkResolver)}>
            <Imgix
              sizes="40px"
              src={item.websiteIcon.url}
              className={styles.websiteImage}
            />
          </a>
        ))}
      </div>
    </section>
  );
};

export default FooterFollow;
