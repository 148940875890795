import React, { useRef, useState, useEffect } from 'react';

import SwiperCore, { Pagination, Navigation } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/components/navigation/navigation.min.css';
import cn from 'classnames';

import productCarouselStyle from '../ProductCarousel/index.module.scss';

import { linkResolver } from '../../prismic-configuration';
import { Link } from 'prismic-reactjs';
import styles from './index.module.scss';
import Imgix from 'react-imgix';
import { screenWidth } from '../../styles/variables';

const IMGIX_SIZES = `;
  (max-width: ${screenWidth.maxScreenLandscape}) 100vw,
  (max-width: ${screenWidth.maxScreenSmall}) 50vw,
  33vw
`;

SwiperCore.use([Pagination]);

const MySlice = ({ slice }) => {
  const numberOfSlides = slice.items.length;

  /* Force re-render for refs to be taken into account */
  const nextEltRef = useRef(null);
  const prevEltRef = useRef(null);

  const [forceRerender, setForceRerender] = useState(false);
  useEffect(() => {
    setForceRerender(true);
  }, []);

  return (
    <section
      className={cn(productCarouselStyle.productCarousel, {
        [productCarouselStyle['productCarousel-3']]: numberOfSlides <= 3,
        [productCarouselStyle['productCarousel-2']]: numberOfSlides <= 2,
        [productCarouselStyle['productCarousel-1']]: numberOfSlides <= 1,
      })}
    >
      <div
        ref={prevEltRef}
        className={cn('swiper-button-prev', styles['swiper-left'])}
      />
      <div
        ref={nextEltRef}
        className={cn('swiper-button-next', styles['swiper-right'])}
      />
      <Swiper
        pagination={{
          dynamicBullets: true,
          clickable: true,
        }}
        navigation={{
          nextEl: nextEltRef.current,
          prevEl: prevEltRef.current,
        }}
        slidesPerView={1.1}
        grabCursor
        centeredSlides
        centeredSlidesBounds
        breakpoints={{
          768: {
            slidesPerView: 2,
            centeredSlides: false,
            centeredSlidesBounds: false,
          },
          992: {
            slidesPerView: 3,
            centeredSlides: false,
            centeredSlidesBounds: false,
          },
        }}
        watchOverflow={true}
      >
        {slice.items.map((item) => (
          <SwiperSlide key={item.id}>
            <div className={styles['slide-content']}>
              {item.title ? (
                <div className={styles['title']}>{item.title}</div>
              ) : null}
              <Imgix
                sizes={IMGIX_SIZES}
                src={item.image.url}
                alt={item.image.alt}
                className={styles['image-wrapper']}
                imgixParams={{
                  trim: 'auto',
                }}
              />

              {item.subtitle ? (
                <div className={styles['description']}>{item.subtitle}</div>
              ) : null}
              {!!item.ctaLink && !!item.ctaText ? (
                <a
                  className={cn(
                    'swiper-no-swiping',
                    'button--dark',
                    'width-100p',
                    styles['cta'],
                  )}
                  href={Link.url(item.ctaLink, linkResolver)}
                >
                  {item.ctaText}
                </a>
              ) : null}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default MySlice;
