import React from 'react';
import { RichText, Link } from 'prismic-reactjs';
import { linkResolverFromRouter } from '../../prismic-configuration';
import styles from './index.module.scss';
import { useRouter } from 'next/router';

const CtaComp = ({ slice }) => {
  const router = useRouter();
  return (
    <div className={styles['cta-wrapper']}>
      <a href={Link.url(slice.primary.ctaLink, linkResolverFromRouter(router))}>
        <button className="button--dark">
          <span className="padding-h-24">{slice.primary.ctaText}</span>
        </button>
      </a>
      {slice.primary.subtitle ? (
        <div className={styles['button-subtitle']}>
          <RichText
            render={slice.primary.subtitle}
            linkResolver={linkResolverFromRouter(router)}
          />
        </div>
      ) : null}
    </div>
  );
};

export default CtaComp;
