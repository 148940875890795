import { useEffect, useState, useRef } from 'react';
import IProduct from '../../interfaces/product';
import IProductPage from '../../interfaces/productPage';
import {
  getPerfumesOptionsFromProduct,
  getTexturesOptionsFromProduct,
  getWeightsOptionsFromProduct,
} from '../../utils/product';
import { getAvailableVariantsFromReference } from '../../utils/variant';

const useCustomizationOptions = (
  product: IProduct,
  productPage: IProductPage,
  contenance: number,
  texture: number,
  setWeight: (_: any) => void,
  setTexture: (_: any) => void,
  setPerfume: (_: any) => void,
) => {
  const texturesOptions = useRef(
    getTexturesOptionsFromProduct(product, productPage),
  );
  const weightsOptions = useRef(
    getWeightsOptionsFromProduct(
      product,
      texturesOptions.current.map((v) => v.value),
    ),
  );
  const perfumesOptions = useRef(getPerfumesOptionsFromProduct(product));
  const [currentWeightsOptions, setCurrentWeightsOptions] = useState(
    weightsOptions?.current,
  );
  const [currentTexturesOptions, setCurrentTexturesOptions] = useState(
    texturesOptions?.current,
  );
  const [currentPerfumesOptions, setCurrentPerfumesOptions] = useState(
    perfumesOptions?.current,
  );

  /* If contenance is set filter the available weight options */
  useEffect(() => {
    if (product) {
      const matchingVariant = product.weights.find((w) => w.id === contenance);
      setCurrentTexturesOptions(
        texturesOptions.current.filter((t) =>
          matchingVariant.references.some((r) => r.id === t.value),
        ),
      );
    }
  }, [contenance]);

  /* Filter the weight and form for the texture */
  useEffect(() => {
    if (texture && !contenance) {
      const availableWeightOptions = getAvailableVariantsFromReference(
        texture,
        product.weights,
      );
      setCurrentWeightsOptions(
        weightsOptions?.current.filter((w) =>
          availableWeightOptions.some((w2) => w2.id === w.value),
        ),
      );
    } else {
      setCurrentWeightsOptions(weightsOptions?.current);
    }
  }, [texture]);

  /* If texture not available for specific contenance, reset the texture */
  useEffect(() => {
    if (
      texture &&
      !currentTexturesOptions.some(
        (textureOption) => textureOption.value === texture,
      )
    ) {
      setTexture(null);
    }
  }, [contenance, texture, currentTexturesOptions]);

  /* If only one choice set it to this choice */
  useEffect(() => {
    if (currentWeightsOptions.length === 1) {
      setWeight(currentWeightsOptions[0]);
    }
  }, [currentWeightsOptions, setWeight]);

  useEffect(() => {
    if (currentTexturesOptions.length === 1) {
      setTexture(currentTexturesOptions[0]);
    }
  }, [currentTexturesOptions, setTexture]);

  useEffect(() => {
    if (currentPerfumesOptions.length === 1) {
      setPerfume(currentPerfumesOptions[0]);
    }
  }, [currentPerfumesOptions, setPerfume]);

  return {
    weightsOptions: currentWeightsOptions,
    texturesOptions: currentTexturesOptions,
    perfumesOptions: currentPerfumesOptions,
    maxNumberOfWeightsOptions: weightsOptions.current.length,
    maxNumberOfTexturesOptions: texturesOptions.current.length,
    maxNumberOfPerfumesOptions: perfumesOptions.current.length,
  };
};

export default useCustomizationOptions;
