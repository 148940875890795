import React, { useState } from 'react';
import Link from 'next/link';
import SliceZone from 'next-slicezone';
import cn from 'classnames';
import { useRouter } from 'next/router';

import resolver from '../../sm-resolver.js';
import styles from './index.module.scss';
import { FooterBarInHeader } from '../footerBar/footerBar';
import { urls, URL_KEYWORDS } from '../../utils/urls';
import { NavigationTabUser } from '../../slices/NavigationTab/index';
import { useAuth } from '../../contexts/auth';
import { useOrder } from '../../contexts/order';

const transformFooterToNavigationTab = (footer) => {
  return footer.data.body
    .filter((footer) => footer.slice_type === 'footer_menu')
    .map((footer) => ({
      slice_type: 'navigation_tab',
      isFromFooter: true,
      variation: footer.variation,
      version: footer.version,
      primary: {
        title: footer.primary.firstLevelTitle,
        image: {},
        link: {},
      },
      items: footer.items.map((footerItem) => ({
        level: '3',
        title: footerItem.secondLevelTitle,
        link: footerItem.link,
      })),
    }));
};

interface NavigationMenuButtonProps {
  url:
    | {
        pathname: string;
        query: { [x: string]: string };
      }
    | string;
  alt: string;
  img: string;
  hoveredImg: string;
  numberOfItems?: number;
}
const NavigationMenuButton = ({
  url,
  alt,
  img,
  hoveredImg,
  numberOfItems,
}: NavigationMenuButtonProps) => {
  const [isMouseOver, setIsMouseOver] = useState(false);

  return (
    <Link href={url}>
      <a
        className={cn('button_icon', {
          [styles.shopping__icon]: numberOfItems,
        })}
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
      >
        <img src={isMouseOver || numberOfItems ? hoveredImg : img} alt={alt} />
        {numberOfItems ? (
          <div className={styles.shopping_count}>{numberOfItems}</div>
        ) : null}
      </a>
    </Link>
  );
};

const NavigationMenu = ({ menu, footer, logoH1 = false }) => {
  const router = useRouter();
  const [active, setActive] = useState(false);
  const [activeTab, setActiveTab] = useState(null);

  const { isAuthenticated } = useAuth();
  const { numberOfItems } = useOrder();

  const lastElt = { ...menu.data.body.slice(-1)[0], isLast: true };
  const startElt = menu.data.body.slice(0, -1);

  const slices = [
    ...startElt,
    lastElt,
    ...transformFooterToNavigationTab(footer),
  ].map((x) => ({ ...x, activeTab, setActiveTab }));

  const LogoWrapper = logoH1 ? 'h1' : 'div';

  return (
    <div className={styles.navigation__menu}>
      <div className={styles.menu__left}>
        <button
          onClick={() => setActiveTab(null)}
          className={cn(styles.navigation__button, {
            [styles.hidden]: activeTab === null,
          })}
        >
          <img src={'/svg/chevron_left.svg'} alt="Chevron left" />
        </button>
        <button
          onClick={() => setActive(!active)}
          className={cn(styles.navigation__button, {
            [styles.hidden]: activeTab !== null,
          })}
        >
          <img
            src={active ? '/svg/exit.svg' : '/svg/hamburger.svg'}
            alt="Hamburger icon"
          />
        </button>
        <Link href={urls.HOME}>
          <a>
            <LogoWrapper className={styles.navigation__logo}>
              LABOTÉ
            </LogoWrapper>
          </a>
        </Link>
      </div>

      <header className={cn(styles.menu, { [styles.active]: active })}>
        <nav className={styles.header__navigation}>
          <SliceZone slices={slices} resolver={resolver} />
        </nav>
        <div
          className={cn(styles.header__footer, {
            'display--none': activeTab !== null,
          })}
        >
          <FooterBarInHeader footer={footer} />
        </div>
      </header>

      <div className={styles.menu__right}>
        <div /> {/* empty div for positionning */}
        {isAuthenticated ? (
          <div onClick={() => setActive(false)} className="button_icon">
            <NavigationTabUser
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              i={Number.MAX_SAFE_INTEGER - 2}
              swagSentenceToProtocol={menu.swagSentenceToProtocol}
            />
          </div>
        ) : (
          <NavigationMenuButton
            url={{
              pathname: urls.LOGIN,
              query: {
                [URL_KEYWORDS.REDIRECT]:
                  router.asPath === '/' ? urls.RESULT : router.asPath,
              },
            }}
            alt="user"
            img="/svg/user_icon.svg"
            hoveredImg="/svg/user_icon_active.svg"
          />
        )}
        <NavigationMenuButton
          url={urls.CART}
          alt="shopping cart"
          img="/svg/shopping_cart_icon.svg"
          hoveredImg="/svg/shopping_cart_icon_active.svg"
          numberOfItems={numberOfItems}
        />
      </div>
    </div>
  );
};

export default NavigationMenu;
