import React, { useEffect, useState } from 'react';
import 'lazysizes';
import cn from 'classnames';
import Link from 'next/link';
import { Background } from 'react-imgix';

import styles from './productDisplay.module.scss';
import IProduct from '../../interfaces/product';
import AddToCart from './AddToCart';
import Rating from '../Rating';
import { useAuth } from '../../contexts/auth';
import { useMedia } from '../../contexts/media';
import { IAccessory } from '../../interfaces/accessory';
import { IProductPageDetailledProduct } from '../../interfaces/productPage';
import { OrderItemTypes, useOrder } from '../../contexts/order';
import {
  getTitle,
  getPath,
  getProductType,
  orderItemAddToCartGTM,
} from '../../utils/order_item';
import { getProductImage } from '../../utils/product';

const SEE_MORE = 'Voir plus';
const ADD = 'Ajouter';
const DISCOVER = 'Découvrir';
const ADDED = 'Ajouté !';
const OUT_OF_STOCK = 'Épuisé';

enum ProductDisplayState {
  PREVIEW,
  PERSONNALISATION,
}

interface ProductDisplayProps {
  product?: IProduct;
  productPage?: IProductPageDetailledProduct;
  accessory?: IAccessory;
  withShop: boolean;
}

const ProductDisplay = ({
  product,
  productPage,
  accessory,
  withShop,
}: ProductDisplayProps) => {
  const medias = useMedia();

  const { isAuthenticated, isDiagnosticDone } = useAuth();
  const { addOrderItem, removeOrderItem, findOrderItem } = useOrder();
  const [addedToCart, setAddedToCart] = useState<boolean>(false);

  const [state, setState] = useState(ProductDisplayState.PREVIEW);
  const [isHovered, setIsHovered] = useState(false);

  const productType = getProductType(product, productPage, accessory);
  const actualProduct = productPage ?? product ?? accessory;

  const displayedPicture = getProductImage(actualProduct.images);

  const isOutOfStock = (productPage ?? accessory ?? product).out_of_stock;

  useEffect(() => {
    setAddedToCart(!!findOrderItem(actualProduct, productType));
  }, [findOrderItem]);

  const hasMultiplePricesAvailable =
    !accessory &&
    (product?.weights?.length > 1 || productPage?.product?.weights?.length > 1);
  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <section
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={cn(styles.productWrapper, {
          [styles['product__wrapper--clicked']]:
            state !== ProductDisplayState.PREVIEW,
        })}
      >
        <section className={styles.productContainer}>
          <section className={styles.productHeader}>
            <p className={styles['product-display__title']}>
              {getTitle(product, productPage, accessory)}
            </p>
            <div className={styles.feedback}>
              <Rating
                averageStars={actualProduct?.average_stars}
                reviewCount={actualProduct?.number_of_review}
              />
            </div>
          </section>
          {(product || productPage) &&
          state === ProductDisplayState.PERSONNALISATION ? (
            <AddToCart
              product={product}
              productPage={productPage}
              handleExit={() => setState(ProductDisplayState.PREVIEW)}
              setAddedToCart={setAddedToCart}
            />
          ) : (
            <>
              <div className={styles.product__body}>
                <Background
                  src={medias.productDisplayBackground.url}
                  alt={medias.productDisplayBackground.alt}
                  className={cn(styles['background-image'], {
                    [styles['background-image--not-hovered']]: !isHovered,
                  })}
                >
                  <Link href={getPath(product, productPage, accessory)}>
                    <a>
                      <img
                        data-sizes="auto"
                        data-src={displayedPicture?.picture}
                        src={displayedPicture?.picture}
                        alt={displayedPicture?.alt}
                        className={cn(styles.productImage, 'lazyload')}
                      />
                    </a>
                  </Link>
                </Background>
              </div>
              <section className={styles.productFooter}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: actualProduct.subtitle_fr,
                  }}
                  className={styles.description}
                />
                <span className={styles.price}>
                  {hasMultiplePricesAvailable ? 'À partir de ' : ''}
                  {productPage?.product?.minimal_price ??
                    product?.minimal_price ??
                    accessory?.price}{' '}
                  €
                </span>
                <div className={styles['buttons-wrapper']}>
                  {(() => {
                    if (productPage || accessory) {
                      return (
                        <>
                          <Link href={getPath(product, productPage, accessory)}>
                            <a
                              className={cn(
                                'button--dark',
                                'swiper-no-swiping',
                              )}
                            >
                              {SEE_MORE}
                            </a>
                          </Link>
                          <button
                            onClick={() => {
                              if (addedToCart) {
                                removeOrderItem(actualProduct, productType);
                                setAddedToCart(false);
                              } else {
                                if (productPage) {
                                  setState(
                                    ProductDisplayState.PERSONNALISATION,
                                  );
                                } else if (accessory) {
                                  orderItemAddToCartGTM(
                                    accessory?.title_fr,
                                    null,
                                    null,
                                    null,
                                    accessory.price,
                                  );
                                  addOrderItem(
                                    accessory,
                                    OrderItemTypes.ACCESSORY,
                                    {},
                                  );
                                }
                              }
                            }}
                            className={cn('button--dark', 'swiper-no-swiping', {
                              'button--dark--hovered': addedToCart,
                              'button--disabled': isOutOfStock,
                            })}
                            disabled={isOutOfStock}
                          >
                            {isOutOfStock
                              ? OUT_OF_STOCK
                              : addedToCart
                              ? ADDED
                              : ADD}
                          </button>
                        </>
                      );
                    } else if (
                      withShop &&
                      isAuthenticated &&
                      isDiagnosticDone
                    ) {
                      return (
                        <>
                          <Link href={getPath(product, productPage, accessory)}>
                            <a
                              className={cn(
                                'button--dark',
                                'swiper-no-swiping',
                              )}
                            >
                              {SEE_MORE}
                            </a>
                          </Link>
                          <button
                            onClick={() => {
                              if (addedToCart) {
                                removeOrderItem(actualProduct, productType);
                                setAddedToCart(false);
                              } else {
                                setState(ProductDisplayState.PERSONNALISATION);
                              }
                            }}
                            className={cn('button--dark', 'swiper-no-swiping', {
                              'button--dark--hovered': addedToCart,
                              'button--disabled': isOutOfStock,
                            })}
                            disabled={isOutOfStock}
                          >
                            {isOutOfStock
                              ? OUT_OF_STOCK
                              : addedToCart
                              ? ADDED
                              : ADD}
                          </button>
                        </>
                      );
                    } else {
                      return (
                        <Link href={getPath(product, productPage, accessory)}>
                          <a className={styles['button-full-width']}>
                            <button
                              className={cn(
                                'button--dark',
                                'swiper-no-swiping',
                              )}
                            >
                              {DISCOVER}
                            </button>
                          </a>
                        </Link>
                      );
                    }
                  })()}
                </div>
              </section>
            </>
          )}
        </section>
      </section>
    </div>
  );
};

export default ProductDisplay;
