import React from 'react';
import Imgix, { Picture, Source } from 'react-imgix';
import cn from 'classnames';
import 'lazysizes';

import styles from './index.module.scss';
import { useMedia } from '../../contexts/media';
import { PictureQuestion } from '../question/PictureQuestion';

export const TEXTURE_AND_PERFUME_GUIDE = 'Guide des textures & parfums';

interface TextureAndPerfumeGuideProps {
  handleExit: () => void;
}

const TextureAndPerfumeGuide = ({
  handleExit,
}: TextureAndPerfumeGuideProps) => {
  const {
    textureAndPerfumeGuide: textureAndPerfumeGuidePicture,
    textureAndPerfumeGuideMobile: textureAndPerfumeGuideMobile,
  } = useMedia();
  return (
    <>
      <div className={styles['guide-wrapper']}>
        <Picture>
          <Source
            src={textureAndPerfumeGuideMobile.url}
            alt={textureAndPerfumeGuideMobile.alt}
            htmlAttributes={{ media: '(max-width: 480px)' }}
          />
          <Imgix
            className="lazyload"
            src={textureAndPerfumeGuidePicture.url}
            alt={textureAndPerfumeGuidePicture.alt}
            sizes="100vw"
            attributeConfig={{
              src: 'data-src',
              srcSet: 'data-srcset',
              sizes: 'data-sizes',
            }}
          />
        </Picture>
      </div>
      <div onClick={handleExit} className={cn('exit-button', styles.exit)} />
    </>
  );
};

export default TextureAndPerfumeGuide;
